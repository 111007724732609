import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started-oli-checkout"
    }}>{`Getting Started Oli-Checkout`}</h1>
    <p><em parentName="p">{`Integrations API from multiples platforms`}</em></p>
    <hr></hr>
    <h2 {...{
      "id": "requirements"
    }}>{`Requirements`}</h2>
    <pre><code parentName="pre" {...{}}>{`- URL
- Authorization TOKEN
- X-Browser-Ip (Only create cart)
- X-User-Agent (Only create cart)
`}</code></pre>
    <h2 {...{
      "id": "permissions-keys"
    }}>{`Permissions keys`}</h2>
    <pre><code parentName="pre" {...{}}>{`- Hook orders
- Orders
- Products
- Inventory
`}</code></pre>
    <h2 {...{
      "id": "routes-integrations"
    }}>{`Routes Integrations`}</h2>
    <pre><code parentName="pre" {...{}}>{`- Router POST - /api/v2/carts/
- Router Get - /api/v2/carts/<id>
- Router POST - /api/v2/carts/<id>/items/bulk
- Router Get - /api/v2/products/<id>
- Router Get - /api/v2/products/search
- Router Get - /api/v2/orders/<id>
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "cart-checkout"
    }}>{`Cart Checkout`}</h2>
    <p><em parentName="p">{`Simple generate url to redirect users from shopping cart.`}</em></p>
    <h3 {...{
      "id": "post-router"
    }}>{`POST Router:`}</h3>
    <p><em parentName="p">{`https://Domain/checkout/cart`}</em></p>
    <h4 {...{
      "id": "request"
    }}>{`Request:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "items": {
    "id": "string",
    "quantity": 0,
  },
  "clientProfileData": {
    "liveId": "fake-ab1c-4b59-a65e-38283984ed46"
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response"
    }}>{`Response:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "message": "string",
  "status": "string",
  "data": {
    "url": "https://domainClient.com.br/checkout/wsjrXFQYU8b6Gqc4wwR1jS7kqdamwtYU
  }
}
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "live-sharing-initialize-or-finish"
    }}>{`Live sharing initialize or finish`}</h2>
    <p><em parentName="p">{`Information initialize live sharing for actualized products in live or information finish live sharing get results in live.`}</em></p>
    <h3 {...{
      "id": "post-router-1"
    }}>{`POST Router:`}</h3>
    <p><em parentName="p">{`https://Domain/checkout/status/live`}</em></p>
    <h4 {...{
      "id": "request-1"
    }}>{`Request:`}</h4>
    <p><em parentName="p">{`Example start live`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "isNewStartLive": true,
    "clientProfileData": {
        "liveId": "fake-ab1c-4b59-a65e-38283984ed46",
    }
}
`}</code></pre>
    <h4 {...{
      "id": "response-1"
    }}>{`Response:`}</h4>
    <p><em parentName="p">{`Response start live`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    {
        "message": null,
        "statusCode": 200,
        "status": "SUCCESS",
        "data": {
            "skuId": "PU08W67272CHA34",
            "totalQuantity": 4
        }
    },
    {
        "message": null,
        "statusCode": 200,
        "status": "SUCCESS",
        "data": {
            "skuId": "PU08W67272CHA34",
            "totalQuantity": 3
        }
    },
    ... more items actualized
}
`}</code></pre>
    <h4 {...{
      "id": "request-2"
    }}>{`Request:`}</h4>
    <p><em parentName="p">{`Example end live sharing`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "isNewStartLive": false,
    "clientProfileData": {
        "liveId": "fake-ab1c-4b59-a65e-38283984ed46"
    }
}
`}</code></pre>
    <h4 {...{
      "id": "response-2"
    }}>{`Response:`}</h4>
    <p><em parentName="p">{`Example end live sharing`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "SalesItems": {
    "Count": 104,
    "Items":[
        {
        "creationDate": "2022-02-10T23:28:14.0000000+00:00",
        "orderId": "236744506123sa4d519457113f0065324",
        "authorizedDate": "2022-02-10T23:28:21.0000000+00:00",
        "orderFormId": "a2asd124506ec4d519457113f00653240",
        "paymentApprovedDate": "2022-02-10T23:28:25.0000000+00:00",
        },
    ]
  }
}
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "get-products"
    }}>{`Get products`}</h2>
    <p><em parentName="p">{`Choice products from live`}</em></p>
    <h3 {...{
      "id": "post-router-2"
    }}>{`POST Router:`}</h3>
    <p><em parentName="p">{`https://Domain/checkout/get/products`}</em></p>
    <h4 {...{
      "id": "request-3"
    }}>{`Request:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "keyword": "camisa",
    "clientProfileData": {
        "liveId": "1b929f5d-ab1c-4b59-a6e5-38283984ed46"
    }
}
`}</code></pre>
    <h4 {...{
      "id": "response-3"
    }}>{`Response:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "data": [
        {
            "product": {
                "productId": "PU08W22090CHA",
                "productName": "Camisa Example in store",
                "productTitle": "Camisa Example in store",
                "description": "Description keyWord",
                "metaTagDescription": "Meta Description KeyWord",
                "Items": [
                    {
                        "images": [
                            {
                                "imageUrl": "https://domainClient/client/2022/04/08/10_4_8_855_pantachouchampagne2.jpg?v=1649440242"
                            }
                        ],
                        "itemId": "PU08W22090CHA33",
                        "name": "Camisa Example in store - P",
                        "nameComplete": "Camisa Example in store - P",
                        "complementName": "Camisa Example in store",
                        "cores": [
                            "Rosa"
                        ],
                        "tamanhos": [
                            "33"
                        ]
                    },
                    {
                        "images": [
                            {
                                "imageUrl": "https://domainClient/client/2022/04/08/10_4_8_855_pantachouchampagne2.jpg?v=1649440242"
                            }
                        ],
                        "itemId": "PU08W22090CHA34",
                        "name": "Camisa Example in store - M",
                        "nameComplete": "Camisa Example in store - M",
                        "complementName": "Camisa Example in store",
                        "cores": [
                            "Rosa"
                        ],
                        "tamanhos": [
                            "34"
                        ]
                    }
                ]
            }
        }
    ],
    "status": "SUCCESS",
    "statusCode": 200
}
`}</code></pre>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      